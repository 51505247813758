import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Component } from 'react';
import { updateParticipantSquares } from '../actions/participantActions'
import { Redirect } from 'react-router';
import BingoCard from '../scripts/BingoCardScavenger';

class Signup extends Component {

    state = {
        participantAdded : false
    }

    handleParticipantSignup = (e) => {
        e.preventDefault();

        let classType = e.target.elements.class_focus.value;
        let newBingoCard = new BingoCard(classType);

        let newParticipant = {
                    leaderboardName : e.target.elements.participant.value.toLowerCase().trim(),
                    displayLeaderboard : e.target.elements.participant.value.trim(),
                    percentComplete : 0,
                    firstName : e.target.elements.firstName.value,
                    lastName : e.target.elements.lastName.value,
                    game : {
                        type : classType,
                        squares : newBingoCard.buildBingoCard()
                    },
                    mileageGoal : e.target.elements.mileageGoal.value,
                    milesCompleted : 0,
                    milesLog : []
        }
        this.props.updateParticipantSquares(newParticipant);
        this.setState({ participantAdded : true })
    }

    render() {
        if (this.state.participantAdded){
            return (<Redirect to="/"/>)
        }
        return (
            <div className="space-form">
                <h2>Not in the list?  Sign-up here!</h2>
                <form onSubmit={this.handleParticipantSignup}>

                    <label htmlFor="participant">What's your leaderboard name? (Please no #'s)</label>
                    <input type="text" id="participant" name="participant" required />
                    <br></br>

                    <label htmlFor="firstName">First Name?</label>
                    <input type="text" id="firstName" name="firstName" />
                    <br></br>

                    <label htmlFor="lastName">Last Name?</label>
                    <input type="text" id="lastName" name="lastName" />
                    <br></br>

                    <label htmlFor="mileageGoal">How many miles do you plan to log this month?</label>
                    <input type="number" id="mileageGoal" name="mileageGoal" />
                    <br></br>
                    {/* onChange={(event) => this.updateValue(event)} */}
                    <input type="radio" id="bike" name="class_focus" value="bike" />
                    <label htmlFor="bike">I want to ride!</label>
                    <br></br>
                    <input type="radio" id="pzone" name="class_focus" value="power zone" />
                    <label htmlFor="pzone">I like to be in the zone!</label>
                    <br></br>
                    <input type="radio" id="tread" name="class_focus" value="tread" />
                    <label htmlFor="tread">I'd rather run!</label>
                    <br></br>
                    <input type="radio" id="both" name="class_focus" value="bike+tread" />
                    <label htmlFor="both">Ride &amp; run, please!</label>
                    <br></br>

                    <button className="form-fields save_updates">Let's Go</button>
                </form>
                <h5>You will be redirected to the main page after clicking the button.  Check for your entry there.</h5>
                

            </div>
        );
    }
}

const mapStateToProps = (state) => (
    {
        selectedParticipant: state.participant.selectedParticipant,
        card: state.challenge
    }
)

const mapDispatchToProps = dispatch => bindActionCreators({
    updateParticipantSquares
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Signup)