export const classList = [
    { instructor: "Collect a Golden Badge", classType: "scavenger", timesUsed: 0 },
    { instructor: "Collect the 2022 LHHM Badge", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor wearing a costume", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor wearing a costume", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class featuring the group 'Ghost Town DJs'", classType: "scavenger", timesUsed: 0 }, 
    { instructor: "Class featuring a group with 'midnight' in their name", classType: "scavenger", timesUsed: 0 },
    { instructor: "Instructor wearing a hat backwards", classType: "scavenger", timesUsed: 0 },
    { instructor: "Re-take a class and top your previous output", classType: "scavenger", timesUsed: 0 },
    { instructor: "Artist series class featuring an artist you haven't heard of before", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with the newest Peloton instructor", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with the newest cycling or tread instructor", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with the newest yoga instructor", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with an instructor who has an October birthday", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class recorded in October of a prior year", classType: "scavenger", timesUsed: 0 },
    { instructor: "Two (or more) for One class", classType: "scavenger", timesUsed: 0 },
    { instructor: "High 5 someone playing our bingo game", classType: "scavenger", timesUsed: 0 },
    { instructor: "Live class", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with 80s playlist", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with 90s playlist", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with 70s playlist", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with 2000s playlist", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with 2010s playlist", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with an instructor who has a pet", classType: "scavenger", timesUsed: 0 },
    { instructor: "Live from Home class", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with the song 'Bad Moon Rising'", classType: "scavenger", timesUsed: 0 },
    { instructor: `Class with the song 'Somebody's Watching Me'`, classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with the song 'Spirit in the Sky'", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class in English with an instructor normally teaching in a different language", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that uses a chair", classType: "scavenger", timesUsed: 0 },
    { instructor: "Listen to a Peloton related podcast", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that works the triceps ", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that works the mid back", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that works the chest", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that works the obliques", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class that works the shoulders", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with a shoutout to someone in our bingo game", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class released on your birthday", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class where instructor has pink or blue hair", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with people in the studio (pre or post COVID)", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class in the Great Outdoors", classType: "scavenger", timesUsed: 0 },
    { instructor: "Scenic Class", classType: "scavenger", timesUsed: 0 },
    { instructor: "Guided Scenic Class", classType: "scavenger", timesUsed: 0 },
    { instructor: "Class with views over a million", classType: "scavenger", timesUsed: 0 },
    { instructor: "Get 'On the Leaderboard'", classType: "scavenger", timesUsed: 0 },
    { instructor: `Class that's in a mood`, classType: "scavenger", timesUsed: 0 },
]
