import { classList } from "./scavengerList"

class BingoCard {
    constructor(cardType) {
        this.cardType = "scavenger";
        this.bingoCard = [];
        this.classList = classList;
    }

    buildBingoCard() {

        this.classList.forEach(item => { item.timesUsed = 0 });
        // let classUseCap = 1;

        // if (this.cardType !== "bike+tread") {
        //     for (let square = 0; square < 12; square++) {

        //         if (this.cardType === "power zone") {
        //             classUseCap = 3;
        //         }

        //         let foundSquare = this.findClass(classUseCap, this.cardType);
        //         this.bingoCard.push({
        //             squareID: square,
        //             instructor: foundSquare.instructor,
        //             classType: foundSquare.classType,
        //             planDate: null,
        //             complete: false
        //         });
        //     }
        // } else {

        //     for (let square = 0; square < 6; square++) {
        //         let foundSquare = this.findClass(classUseCap, "bike");
        //         this.bingoCard.push({
        //             squareID: square,
        //             instructor: foundSquare.instructor,
        //             classType: foundSquare.classType,
        //             planDate: null,
        //             complete: false
        //         });
        //     }

        //     for (let square = 6; square < 12; square++) {
        //         let foundSquare = this.findClass(classUseCap, "tread");
        //         this.bingoCard.push({
        //             squareID: square,
        //             instructor: foundSquare.instructor,
        //             classType: foundSquare.classType,
        //             planDate: null,
        //             complete: false
        //         });
        //     }
        // }

        for (let square = 0; square < 35; square++) {
            let classUseCap = 1;

            let foundSquare = this.findClass(classUseCap, "scavenger");
            this.bingoCard.push({
                squareID: square,
                instructor: foundSquare.instructor,
                // classType: foundSquare.classType,
                planDate: null,
                complete: false
            });
        }

        // for (let square = 20; square < 25; square++) {
        //     let classUseCap = 1;

        //     let foundSquare = this.findClass(classUseCap, "yoga");
        //     this.bingoCard.push({
        //         squareID: square,
        //         instructor: foundSquare.instructor,
        //         classType: foundSquare.classType,
        //         planDate: null,
        //         complete: false
        //     });
        // }

        // for (let square = 25; square < 27; square++) {

        //     this.bingoCard.push({
        //         squareID: square,
        //         instructor: "",
        //         classType: "You Choose!",
        //         planDate: null,
        //         complete: false
        //     });
        // }

        // for (let square = 27; square < 32; square++) {
        //     let classUseCap = 1;

        //     let foundSquare = this.findClass(classUseCap, "meditation");
        //     this.bingoCard.push({
        //         squareID: square,
        //         instructor: foundSquare.instructor,
        //         classType: foundSquare.classType,
        //         planDate: null,
        //         complete: false
        //     });
        // }

        // for (let square = 32; square < 40; square++) {
        //     let classUseCap = 1;

        //     let foundSquare = this.findClass(classUseCap, "stretch");
        //     this.bingoCard.push({
        //         squareID: square,
        //         instructor: foundSquare.instructor,
        //         classType: foundSquare.classType,
        //         planDate: null,
        //         complete: false
        //     });
        // }

        // for (let square = 40; square < 45; square++) {
        //     let classUseCap = 1;

        //     let foundSquare = this.findClass(classUseCap, "other");
        //     this.bingoCard.push({
        //         squareID: square,
        //         instructor: foundSquare.instructor,
        //         classType: "wild card",
        //         planDate: null,
        //         complete: false
        //     });
        // }

        return this.bingoCard;
    }

    findClass(classUseCap, cardType) {

        let classFound = false;
        let randomNum = 0;

        while (!classFound) {
            randomNum = Math.floor(Math.random() * this.classList.length);

            if (this.classList[randomNum].classType === cardType &&
                this.classList[randomNum].timesUsed < classUseCap) {
                this.classList[randomNum].timesUsed++;
                classFound = true;
            }
        }

        return this.classList[randomNum];
    }

}

export default BingoCard